import { default as localFeatureFlags } from './localFeatureFlags.json';
import { default as productionFeatureFlags } from './productionFeatureFlags.json';
import { default as stagingFeatureFlags } from './stagingFeatureFlags.json';
import { FeatureFlag } from './types';

const featureFlagsMap: { [env: string]: string[] } = {
    production: productionFeatureFlags,
    staging: stagingFeatureFlags,
    local: localFeatureFlags
};

export const isValidFeatureFlag = (flag: string): flag is FeatureFlag => {
    const values: string[] = Object.values(FeatureFlag);
    return values.includes(flag);
};

export const loadFeatureFlags = (env: string) => {
    const flags = featureFlagsMap[env] || [];

    return flags.reduce<FeatureFlag[]>((accumulator, flag) => {
        if (isValidFeatureFlag(flag)) {
            accumulator.push(flag);
        }
        return accumulator;
    }, []);
};
