import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormListOperation } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TemplateId } from '@olxeu-monetization/product-catalog-api-client';
import { ModalContext } from '../../../../context';
import { Messages } from '../../../../intl';
import { TemplateSelectionModal } from '../../../../modals/TemplateSelectionModal';
import { mapTemplateToLabel } from '../../helpers';

interface Props {
    selectedIds: TemplateId[];
    labelListOperation: FormListOperation;
    setActiveKey?: (key: TemplateId) => void;
}

export const TemplatePicker = ({
    labelListOperation,
    selectedIds,
    setActiveKey
}: Props) => {
    const modal = useContext(ModalContext);
    const { formatMessage } = useIntl();

    const handleModalShow = (
        add: (value?: unknown) => void,
        selectedIds: TemplateId[]
    ) => {
        modal.show(
            <TemplateSelectionModal
                selectedIds={selectedIds}
                onSelect={(template) => {
                    const mappedLabel = mapTemplateToLabel(template);

                    add(mappedLabel);
                    setActiveKey?.(template.id);
                }}
            />,
            {
                footer: null,
                centered: true,
                width: 700
            }
        );
    };

    return (
        <Button
            key="add-label"
            icon={<PlusOutlined />}
            onClick={() => handleModalShow(labelListOperation.add, selectedIds)}
        >
            {formatMessage(Messages['common.button.add-label'])}
        </Button>
    );
};
