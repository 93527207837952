import { ConfigContextType } from '../context';
import { loadFeatureFlags } from '../featureFlags';
import { buildOktaMarketConfigs } from '../providers';
import { Path } from '../routing';

const oktaAuthMarketClientIds = process.env.OKTA_AUTH_SERVICE_CLIENT_IDS || '';
const oktaAuthSingleClientId = process.env.OKTA_AUTH_SERVICE_CLIENT_ID || '';

export const Config: ConfigContextType = {
    services: {
        productCatalogService: {
            baseUrl: process.env.PRODUCT_CATALOG_SERVICE_BASE_URL
        },
        upsService: {
            baseUrl: process.env.UPS_SERVICE_BASE_URL
        },
        oktaAuthService: {
            marketConfigs: buildOktaMarketConfigs(
                `${oktaAuthMarketClientIds};global=${oktaAuthSingleClientId}`
            ),
            clientId: process.env.OKTA_AUTH_SERVICE_CLIENT_ID,
            issuer: process.env.OKTA_AUTH_SERVICE_ISSUER,
            redirectUri: `${window.location.origin}${Path.SignInCallback}`,
            scopes: ['openid', 'profile', 'email', 'groups'],
            pkce: true
        }
    },
    application: {
        environment: process.env.APP_ENV,
        featureFlags: loadFeatureFlags(process.env.APP_ENV)
    },
    intl: {
        locale: window.navigator.language
    }
};
