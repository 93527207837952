import { useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AugmentedTableInterface, Table, Tag } from 'antd';
import {
    TableCurrentDataSource,
    TablePaginationConfig
} from 'antd/lib/table/interface';
import { useTheme } from 'styled-components';
import {
    TemplateListItem,
    TemplateName
} from '@olxeu-monetization/product-catalog-api-client';
import { ModalTitle } from '../../components/ModalTitle';
import { ModalContext } from '../../context';
import { useProductCatalogService } from '../../helpers';
import { Messages } from '../../intl';
import { usePromise } from '../../utils';
import { buildTableColumns, TableDataSource, TableFilters } from './columns';
import { buildTemplatesDataSource, mapTableFiltersToFilters } from './utils';

const PaginationInitialPageIndex = 0;
const PaginationDefaultPageSize = 10;

interface TemplateListParams {
    search?: string;
    limit: number;
    page: number;
}

const TemplatesTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    selectedIds: TemplateName[];
    onSelect?: (template: TemplateListItem) => void;
}

export const TemplateSelectionModal = ({ selectedIds, onSelect }: Props) => {
    const { onClose } = useContext(ModalContext);
    const theme = useTheme();
    const intl = useIntl();
    const { formatMessage } = intl;

    const [params, setParams] = useState<TemplateListParams>({
        page: PaginationInitialPageIndex,
        limit: PaginationDefaultPageSize
    });

    const { getTemplates } = useProductCatalogService();

    const templatesResult = usePromise({
        variables: {
            search: params.search,
            limit: params.limit,
            offset: params.page * params.limit
        },
        promiseBuilder: async (variables) => {
            return getTemplates(variables);
        }
    });

    const loading = templatesResult.loading && !templatesResult.data;
    const templates = templatesResult.data?.data;
    const templatesCount = templatesResult.data?.metadata.totalCount ?? 0;

    const handleSelect = useCallback(
        (id: string) => {
            const template = templates?.find((template) => template.id === id);

            if (!template) {
                throw new Error('Could not resolve translation by given id');
            }

            onSelect?.(template);
            onClose();
        },
        [templates, onSelect, onClose]
    );

    const columns = useMemo(() => {
        const filters = {
            search: params.search
        };

        return buildTableColumns({
            filters,
            intl,
            onSelect: handleSelect
        });
    }, [intl, handleSelect, params.search]);

    const dataSource = useMemo(() => {
        if (!templates) {
            return [];
        }

        return buildTemplatesDataSource(templates, selectedIds);
    }, [templates, selectedIds]);

    const handleOnChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: unknown,
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;

                if (nextPageIndex !== params.page) {
                    setParams((params) => ({
                        ...params,
                        page: nextPageIndex
                    }));
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(filters);

                setParams((params) => ({
                    ...params,
                    ...nextFilters
                }));

                break;
            }
            case 'sort': {
                break;
            }
        }
    };

    return (
        <>
            <ModalTitle
                title={formatMessage(Messages['common.label.select-template'])}
                tags={
                    <Tag color={theme.colors.tagCounter}>
                        {formatMessage(Messages['common.tag.available'], {
                            count: templatesCount
                        })}
                    </Tag>
                }
            />
            <TemplatesTable
                data-testid="select-template-table"
                columns={columns}
                dataSource={dataSource}
                onChange={handleOnChange}
                loading={loading}
                pagination={{
                    total: templatesCount,
                    pageSize: params.limit,
                    current: params.page + 1
                }}
            />
        </>
    );
};
