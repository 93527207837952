import { FormattedMessage, IntlShape } from 'react-intl';
import { AugmentedColumnsType, Button } from 'antd';
import { renderSearchIcon, SearchFilter } from '../../components/Table';
import { Messages } from '../../intl';

export interface Filters {
    search?: string;
}

export type TableDataSource = {
    id: string;
    name: string;
    translation: string;
    selected: boolean;
};

export type TableFilters = {
    name: string[] | null;
    translation: string[] | null;
    actions: null;
};

interface Options {
    filters: Filters;
    intl: IntlShape;
    onSelect?: (id: string) => void;
}

export const buildTableColumns = ({
    filters,
    intl,
    onSelect
}: Options): AugmentedColumnsType<TableDataSource, TableFilters> => {
    return [
        {
            title: <FormattedMessage {...Messages['common.label.name']} />,
            key: 'name',
            dataIndex: 'name',
            filterDropdown: (props) => (
                <SearchFilter
                    {...props}
                    placeholder={intl.formatMessage(
                        Messages['filters.placeholder.name-search']
                    )}
                />
            ),
            filterIcon: renderSearchIcon,
            filteredValue: filters.search ? [filters.search] : []
        },
        {
            title: (
                <FormattedMessage {...Messages['common.label.translation']} />
            ),
            key: 'translation',
            dataIndex: 'translation'
        },
        {
            title: <FormattedMessage {...Messages['common.label.actions']} />,
            key: 'actions',
            dataIndex: 'id',
            align: 'right',
            render: (id: string, record) => (
                <Button
                    size="small"
                    type="primary"
                    disabled={record.selected}
                    onClick={() => onSelect?.(id)}
                    data-testid={`templates-modal-select-button[${id}]`}
                >
                    <FormattedMessage {...Messages['common.button.select']} />
                </Button>
            )
        }
    ];
};
