import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
    AugmentedRoute,
    AugmentedTableInterface,
    Button,
    CheckboxProps,
    Popconfirm,
    SpinProps,
    Table,
    TablePaginationConfig,
    Tag
} from 'antd';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import {
    Category,
    CategorySet,
    CategorySetId
} from '@olxeu-monetization/product-catalog-api-client';
import { ActionsBar } from '../../components/ActionsBar';
import { PageHeader } from '../../components/PageHeader';
import { PermissionGuard } from '../../components/PermissionGuard';
import { Messages } from '../../intl';
import { OktaAccessScopeGrant } from '../../providers';
import { buildLink, MarketId, Path } from '../../routing';
import {
    buildTableColumns,
    Filters,
    TableDataSource,
    TableFilters
} from './columns';
import {
    buildCategoryOptions,
    buildCategorySetsDataSource,
    buildTypeOptions,
    mapTableFiltersToFilters
} from './utils';

const CategorySetsTable = Table as AugmentedTableInterface<
    TableDataSource,
    TableFilters
>;

interface Props {
    marketId: MarketId;
    categorySets: CategorySet[];
    breadcrumbRoutes: AugmentedRoute[];
    categorySetsCount: number;
    categorySetsLoading: boolean;
    categories: Category[];
    pageIndex: number;
    pageSize: number;
    filters: Filters;
    onFiltersChange?: (filters: Filters) => void;
    onPageIndexChange?: (pageIndex: number) => void;
    onPageSizeChange?: (pageSize: number) => void;
    onCategorySetsRemove?: (categorySetIds: CategorySetId[]) => void;
}

export const CategorySetListLayout = ({
    marketId,
    categorySets,
    breadcrumbRoutes,
    categorySetsCount,
    categorySetsLoading,
    categories,
    pageIndex,
    pageSize,
    filters,
    onPageIndexChange,
    onPageSizeChange,
    onFiltersChange,
    onCategorySetsRemove
}: Props) => {
    const theme = useTheme();
    const intl = useIntl();

    const [selectedCategorySetIds, setSelectedCategorySetIds] = useState<
        CategorySetId[]
    >([]);

    const categoryOptions = useMemo(() => {
        return buildCategoryOptions(categories);
    }, [categories]);

    const typeOptions = useMemo(() => {
        return buildTypeOptions();
    }, []);

    const dataSource = useMemo(() => {
        return buildCategorySetsDataSource(categorySets, categories);
    }, [categorySets, categories]);

    const handleChange = (
        pagination: TablePaginationConfig,
        filters: TableFilters,
        sorter: SorterResult<TableDataSource> | SorterResult<TableDataSource>[],
        extra: TableCurrentDataSource<TableDataSource>
    ) => {
        switch (extra.action) {
            case 'paginate': {
                const nextPageIndex = Number(pagination.current) - 1;
                const nextPageSize = Number(pagination.pageSize);

                if (nextPageIndex !== pageIndex) {
                    onPageIndexChange?.(nextPageIndex);
                }

                if (nextPageSize !== pageSize) {
                    onPageSizeChange?.(nextPageSize);
                }

                break;
            }
            case 'filter': {
                const nextFilters = mapTableFiltersToFilters(
                    filters,
                    categoryOptions
                );

                onFiltersChange?.(nextFilters);
                setSelectedCategorySetIds([]);

                break;
            }
            default:
                return;
        }
    };

    const columns = useMemo(() => {
        return buildTableColumns({
            categoryOptions,
            typeOptions,
            filters,
            marketId,
            intl
        });
    }, [categoryOptions, filters, intl, marketId, typeOptions]);

    return (
        <div data-testid="category-set-list-layout">
            <PageHeader
                title={intl.formatMessage(
                    Messages['common.label.category-sets']
                )}
                tags={
                    categorySetsCount ? (
                        <Tag color={theme.colors.tagCounter}>
                            {intl.formatMessage(
                                Messages['common.tag.total-count'],
                                {
                                    count: categorySetsCount
                                }
                            )}
                        </Tag>
                    ) : undefined
                }
                actions={[
                    <PermissionGuard
                        permission={OktaAccessScopeGrant.ProductCatalogWrite}
                        key="create-category-set"
                    >
                        {(eligible) => (
                            <Link
                                to={buildLink(Path.CategorySetCreate, {
                                    marketId
                                })}
                            >
                                <Button
                                    icon={<PlusOutlined />}
                                    disabled={!eligible}
                                >
                                    {intl.formatMessage(
                                        Messages[
                                            'common.button.create-new-category-set'
                                        ]
                                    )}
                                </Button>
                            </Link>
                        )}
                    </PermissionGuard>
                ]}
                breadcrumbRoutes={breadcrumbRoutes}
                hideBackButton={true}
            />
            <CategorySetsTable
                data-testid="category-sets-table"
                rowSelection={{
                    type: 'checkbox',
                    preserveSelectedRowKeys: true,
                    selectedRowKeys: selectedCategorySetIds,
                    getCheckboxProps: (record: TableDataSource) => {
                        return {
                            'data-cy': `category-set-checkbox[${record.key}]`
                        } as CheckboxProps;
                    },
                    onChange: (selectedProducts) =>
                        setSelectedCategorySetIds(
                            selectedProducts as CategorySetId[]
                        )
                }}
                columns={columns}
                dataSource={dataSource}
                loading={
                    categorySetsLoading &&
                    ({ 'data-cy': 'table-loader' } as SpinProps)
                }
                pagination={{
                    total: categorySetsCount,
                    pageSize,
                    current: pageIndex + 1
                }}
                onChange={handleChange}
            />
            <ActionsBar mt={16}>
                <Popconfirm
                    title={intl.formatMessage(
                        Messages[
                            'common.prompt-message.remove-selected-category-sets-confirm'
                        ],
                        {
                            count: selectedCategorySetIds.length
                        }
                    )}
                    okText={intl.formatMessage(Messages['common.label.yes'])}
                    cancelText={intl.formatMessage(Messages['common.label.no'])}
                    onConfirm={() => {
                        onCategorySetsRemove?.(selectedCategorySetIds);
                        setSelectedCategorySetIds([]);
                    }}
                    disabled={selectedCategorySetIds.length === 0}
                >
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        disabled={selectedCategorySetIds.length === 0}
                    >
                        {intl.formatMessage(
                            Messages['common.button.remove-selected'],
                            {
                                count: selectedCategorySetIds.length
                            }
                        )}
                    </Button>
                </Popconfirm>
            </ActionsBar>
        </div>
    );
};
